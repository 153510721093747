import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faBars,
  faXmark,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link, Navigate, useNavigate, useRoutes } from "react-router-dom";
import Logo from "../Assets/logo.png"; // Add logo image
import WhiteLogo from "../Assets/white-logo.png"; // Add white logo image

function Navbar({ home = false }) {
  const [nav, setNav] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`navbar-section ${isScrolled || !home ? "scrolled" : ""}`}>
      {/* Logo Image */}
      <Link to="/">
        <img
          src={isScrolled || !home ? Logo : WhiteLogo}
          alt="Logo"
          className="navbar-logo"
        />
      </Link>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/" className="navbar-links">
            الرئيسية
          </Link>
        </li>
        <li>
          <a href="#footer" className="navbar-links">
            اتصل بنا
          </a>
        </li>
      </ul>

      {/* Booking Buttons */}
      <div className="nav-buttons">
        <a href="https://healthcare.silverpyramids.com/">
          <button className="nav-btn-appointment" type="button">
            احجز موعدك
          </button>
        </a>
        <a href="http://hospitality.silverpyramids.com/">
          <button className="nav-btn-stay" type="button">
            احجز اقامتك
          </button>
        </a>
      </div>

      {/* Mobile Navbar */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/">
              الرئيسية
            </Link>
          </li>
          <li>
            <a onClick={openNav} href="#footer">
              اتصل بنا
            </a>
          </li>
        </ul>

        {/* Mobile Booking Buttons */}
        <div className="nav-buttons-mobile">
          <a href="https://healthcare.silverpyramids.com/">
            <button className="nav-btn-appointment" type="button">
              احجز موعدك
            </button>
          </a>
          <a href="http://hospitality.silverpyramids.com/">
            <button className="nav-btn-stay" type="button">
              احجز اقامتك
            </button>
          </a>
        </div>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon bars"
        />
      </div>
    </div>
  );
}

export default Navbar;
