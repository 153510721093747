import React from "react";

function InformationCard({ title, description, image, link }) {
  return (
    <div className="info-card">
      <img src={image} alt={title} className="info-card-image" />
      <div className="info-card-content">
        <h3 className="info-card-title">{title}</h3>
        <p className="info-card-description">{description}</p>
        <a href={link} className="info-card-link">
          اعرف المزيد
        </a>
      </div>
    </div>
  );
}

export default InformationCard;
