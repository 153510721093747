import React from "react";
import InformationCard from "./InformationCard";
import first from "../Assets/1.webp"; // Example image imports
import second from "../Assets/2.webp";
import third from "../Assets/3.webp";
import fourth from "../Assets/4.webp";
import fifth from "../Assets/5.webp";
import sixth from "../Assets/6.webp";

import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>خدماتنا</span>
        </h3>
        <p className="info-description">
          نقدم لك مجموعة من خدمات التجميل والعناية الشخصية لمساعدتك في الحفاظ
          على جمالك وصحتك، سواء كان ذلك من خلال الجراحات أو التجميل غير الجراحي.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="جراحات الرجال"
          description="الجمال حق للجميع بما فيهم الرجال وقد أصبح الآن للرجال نصيب كبير من جراحات التجميل."
          image={third}
          link="#learn-more"
        />

        <InformationCard
          title="الجراحات الحساسة"
          description="بعد الحمل والولادة تحدث تغيرات قد تؤثر على العلاقة الزوجية، منها زيادة الوزن واتساع المهبل."
          image={second}
          link="#learn-more"
        />

        <InformationCard
          title="التجميل بدون جراحة"
          description="يشمل التجميل بدون جراحة كلا من البوتوكس وحقن الدهون."
          image={first}
          link="#learn-more"
        />

        <InformationCard
          title="عمليات تجميل الجسم"
          description="تعتبر الرشاقة هبة من الله، وهي مطلب طبيعي لكل إنسان."
          image={sixth}
          link="#learn-more"
        />

        <InformationCard
          title="عمليات تجميل الثدي"
          description="لا توجد كريمات أو أجهزة أو هرمونات فعالة، الجراحة عادة ما تكون الحل."
          image={fifth}
          link="#learn-more"
        />

        <InformationCard
          title="عمليات تجميل الوجه والأنف"
          description="الوجه هو مرآة الإنسان وشخصيته، والكل يسعى لجمال الوجه وتناسقه."
          image={fourth}
          link="#learn-more"
        />
      </div>
    </div>
  );
}

export default Info;
