import React from "react";
import ReservationCard from "../Components/ReservationPage";
import Navbar from "../Components/Navbar";

function Appointment() {
  return (
    <div dir="RTL">
      <Navbar />
      <ReservationCard />
    </div>
  );
}

export default Appointment;
