import React from "react";
import ReservationCard from "./ReservationCard";
import {
  faHeartPulse,
  faTruckMedical,
  faTooth,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";
import rush from "../Assets/cards-images/Doctor-amico.png"
import vip from "../Assets/cards-images/Doctors-cuate.png"
import regular from "../Assets/cards-images/Medical-prescription-bro.png"
import Online from "../Assets/cards-images/Online-Doctor-bro.png"

function ReservationPage() {
  return (
    <div className="reserve-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>اختر حجزك الآن</span>
        </h3>
        <p className="info-description">
         نظام متكامل يوفر لك أربع طرق مختلفة لحجز موعد مع
          الطبيب، تم تصميمها لتلبية احتياجات جميع المرضى وتقديم تجربة مريحة
          وسلسة. سواء كنت تبحث عن حجز موعد سريع أو ترغب في تجربة رعاية متميزة،
          هذا النظام يتيح لك المرونة والراحة.
        </p>
      </div>

      <div className="reservation-cards-content">
        <ReservationCard
          title="كشف اونلاين"
          description="حجز الموعد عبر الإنترنت بكل سهولة وسرعة من خلال الموقع أو التطبيق."
          icon={faTruckMedical}
          url={Online}
        />

        <ReservationCard
          title="VIP كشف"
          description="تجربة حجز مميزة تقدم أولوية الخدمة ورعاية خاصة أثناء الزيارة."
          icon={faHeartPulse}
          url={vip}
        />

        <ReservationCard
          title="كشف عادي"
          description=" طريقة الحجز التقليدية للحصول على موعد خلال أيام العمل."
          icon={faTooth}
          url={regular}
        />
                <ReservationCard
          title="كشف مستعجل"
          description=" خيار لحجز الموعد في أقرب وقت متاح للمرضى الذين يحتاجون إلى رعاية سريعة."
          icon={faTooth}
          url={rush}
        />
      </div>
    </div>
  );
}

export default ReservationPage;
