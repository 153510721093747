import React from "react";
import Doctor from "../Assets/doctor-group.webp";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>الأستاذ الدكتور حسام أبو العطا</span>
        </h3>
        <p className="about-description">
          البروفيسور الدكتور حسام ابو العطا جراح تجميل مصري، تلقى تعليمه وتدريبه
          في جامعة عين شمس، كما تدرب في فرنسا وتركيا. متخصص في جراحات تجميل
          الوجه والثدي، وقد تدرب في باريس تحت إشراف الدكتور دانيال مارشاك. يتمتع
          بمهارات عالية في استخدام المناظير في جراحات شد الجبهة والوجه وجراحات
          شد البطن وشفط الدهون.
        </p>

        <h4 className="about-text-title">الدرجات العلمية</h4>

        <SolutionStep
          title="التدريب بعد التخرج"
          description="قسم جراحات التجميل والحروق كلية الطب | جامعة عين شمس، جامعة باشكنت | أنقرة | تركيا، قسم جراحات التجميل | مستشفى نكير | باريس"
        />

        <SolutionStep
          title="الشهادات"
          description="بكالوريوس الطب والجراحة | كلية الطب | جامعة عين شمس، ماجستير ديناميكية إصلاح وتجميل الأنف | كلية الطب | جامعة عين شمس، دكتوراه في جراحة التجميل والليزر | كلية الطب | جامعة عين شمس"
        />
      </div>
    </div>
  );
}

export default About;
