import React, { useEffect, useState } from "react";
import video from "../Assets/demo.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Hero.css";
import kkk from "../Assets/Logo-hero.png";

function Hero() {
  
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      {/* Video background */}
      <video
        className="hero-video-background"
        src={video}
        autoPlay
        loop
        muted
        playsInline
        type="video/mp4"
      />

      {/* Dark overlay on video */}
      <div className="video-overlay"></div>

      <div className="hero-section fade-in">
        <div className="text-section">
          <img src={kkk} alt="logo" style={{ marginBottom: "40px" }} />
          <p className="text-description" style={{ fontSize: "30px" }}>
            مراكز و عيادات{" "}
          </p>
          <h2 className="text-title">الأستاذ الدكتور حسام أبو العطا</h2>
          <p className="text-description">
            أستاذ جراحات التجميل والليزر- كلية الطب جامعة عين شمس
          </p>
          <a href="https://healthcare.silverpyramids.com/">
            <button
              className="text-appointment-btn"
              type="button"
              // onClick={handleBookAppointmentClick}
            >
              <FontAwesomeIcon icon={faCalendarCheck} /> حجز موعد
            </button>
          </a>
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}>
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
