import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";


function ReservationCard(props) {
const navigate = useNavigate()
    const handleBookAppointmentClick = () => {
        navigate("/appointment");
      };

  return (
    <div
      className="reservation-cards"
      style={{ backgroundImage: `url(${props.url})` }}
    >
      <div style={{ backgroundColor: "white", opacity: 0.7, height: "100%" }}>
        <span className="reservation-card-icon">
          <FontAwesomeIcon className="reservation-fa-icon" icon={props.icon} />
        </span>
        <p className="reservation-card-title">{props.title}</p>
        {/* <p className="reservation-card-description">{props.description}</p> */}
        <button className="text-appointment-btn-reserve" onClick={handleBookAppointmentClick}>
            احجز الآن
        </button>
      </div>
    </div>
  );
}

export default ReservationCard;
