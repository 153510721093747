import React, { useState } from "react";
import { Upload, message as antMessage } from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { AudioRecorder } from "react-audio-voice-recorder";
import "../Styles/AppointmentForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "antd/dist/antd.css"; // Import Ant Design CSS

const { Dragger } = Upload;

function AppointmentForm() {
  const [patientName, setPatientName] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [audioBlobs, setAudioBlobs] = useState([]);

  // Handle Image Upload (Ant Design)
  const handleImageUpload = ({ fileList }) => {
    setUploadedImages(fileList);
  };

  // Remove uploaded image
  const removeImage = (file) => {
    const newList = uploadedImages.filter((item) => item.uid !== file.uid);
    setUploadedImages(newList);
  };

  // Add recorded audio
  const addAudioElement = (blob) => {
    setAudioBlobs((prev) => [...prev, blob]);
    toast.success("تم تسجيل الصوت بنجاح");
  };

  // Remove audio recording
  const removeAudio = (index) => {
    const newAudioBlobs = [...audioBlobs];
    newAudioBlobs.splice(index, 1);
    setAudioBlobs(newAudioBlobs);
  };

  // Handle Form Submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!patientName || !whatsappNumber || !messageContent) {
      toast.error("يرجى ملء جميع الحقول المطلوبة");
      return;
    }
    toast.success("تم إرسال النموذج بنجاح");
    setPatientName("");
    setWhatsappNumber("");
    setMessageContent("");
    setUploadedImages([]);
    setAudioBlobs([]);
  };

  return (
    <div className="form-wrapper">
      <h2 className="form-title">الاستاذ الدكتور حسام أبو العطا</h2>

      <form className="form-content" onSubmit={handleSubmit}>
        {/* Image Uploader with Drag & Drop */}
        <label className="form-label">اضافة صور الحالة:</label>
        <Dragger
          multiple
          fileList={uploadedImages}
          onChange={handleImageUpload}
          onRemove={removeImage}
          accept="image/*"
          className="form-uploader"
          showUploadList={false}
          style={{ borderColor: "#c69f73" }}
          customRequest={({ onSuccess }) =>
            setTimeout(() => onSuccess("ok"), 0)
          }>
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ color: "#c69f73" }} />
          </p>
          <p className="ant-upload-text">اسحب الصور هنا أو انقر للتحميل</p>
        </Dragger>

        {/* Show uploaded image thumbnails */}
        <div className="image-preview">
          {uploadedImages.map((file) => (
            <div key={file.uid} className="preview-img">
              <img
                src={URL.createObjectURL(file.originFileObj)}
                alt="thumbnail"
              />
              <DeleteOutlined
                className="remove-btn"
                onClick={() => removeImage(file)}
              />
            </div>
          ))}
        </div>

        {/* Voice Recorder */}
        <label className="form-label">سجل رسالتك الصوتية:</label>
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadOnSavePress={false}
          showVisualizer
        />
        <br />

        {/* Display recorded audio with delete option */}
        {audioBlobs.length > 0 &&
          audioBlobs.map((blob, index) => (
            <div key={index} className="audio-wrapper">
              <audio controls className="audio-player">
                <source src={URL.createObjectURL(blob)} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
              <DeleteOutlined
                className="remove-btn"
                onClick={() => removeAudio(index)}
              />
            </div>
          ))}

        <div className="form-row">
          {/* Full Name Input */}
          <div className="half-width">
            <label className="form-label">الاسم الكامل:</label>
            <input
              type="text"
              value={patientName}
              onChange={(e) => setPatientName(e.target.value)}
              className="form-input"
              placeholder="ادخل اسمك الكامل"
            />
          </div>
          {/* WhatsApp Number Input */}
          <div className="half-width">
            <label className="form-label">رقم الواتساب:</label>
            <input
              type="tel"
              value={whatsappNumber}
              onChange={(e) => setWhatsappNumber(e.target.value)}
              className="form-input"
              placeholder="ادخل رقم الواتساب"
              dir="rtl"
            />
          </div>
        </div>

        {/* Message Input (Auto-adjust size, no dragging) */}
        <label className="form-label">اكتب رسالتك:</label>
        <textarea
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          className="form-input"
          placeholder="اكتب رسالتك هنا"
          style={{ resize: "none" }}
          rows="5"
        />

        {/* Submit Button */}
        <button type="submit" className="form-button">
          إرسال
        </button>
      </form>

      <ToastContainer />
    </div>
  );
}

export default AppointmentForm;
