import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTiktok,
  faYoutube,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="footer-section" id="footer">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">مجموعة مراكز ومستشفيات</p>
            <p className="ft-description">
              الدكتور حسام أبوالعطا
              <br />
              نحن نضمن لكم الجودة والكفاءة العالية التي تضاهي بل تتفوق على
              أوروبا وأمريكا كما أننا ملتزمون أمامكم بتحقيق أحلى وآمن النتائج
              بإذن الله. إذا كنت ترغب بالحصول على معلومات تفصيلية أكثر،
            </p>
          </div>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">
            اختر المكان الأقرب إليك واضغط عليه ليظهر لك على الخريطة
          </p>
          <ul className="ft-list-items">
            <li>
              <a href="#hospital">مستشفى الجميلة</a>
            </li>
            <li>
              <a href="#branch1">فرع المعادي</a>
            </li>
            <li>
              <a href="#branch2">فرع المهندسين</a>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">
            إذا لديك أي استفسار حول العمليات التجميلية اتصل بنا
          </p>
          <ul className="ft-list-items">
            <li>
              <a href="tel:+201140046400">(+2) 011 400 46 400</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2013-2024 الدكتور حسام أبوالعطا. جميع الحقوق محفوظة.</p>
        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.tiktok.com/embed/@drhossamabolatta"
              title="TikTok"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
          </li>

          <li>
            <a
              href="https://www.youtube.com/channel/UCzWqfo9kMuil-QIkly42u2Q"
              title="YouTube"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>

          <li>
            <a
              href="https://www.instagram.com/drhossamabolatta/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>

          <li>
            <a
              href="https://www.facebook.com/drhossamabolattaa?mibextid=ZbWKwL"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
